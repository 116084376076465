import {ClientOptions} from "../List/config";

export const columns = [
  {
    field: 'RowIndex',
    headerName: '序号',
    editable: false,
    width: 55,
    cellClass: 't-c',
  },
  {
    field: 'CompanyName',
    headerName: '公司名称',
    // width: 200,
    cellClass: 't-l'
  },
  {
    field: 'CreateTime',
    headerName: '提交时间',
    // width: 200,
    cellClass: 't-c'
  },
  {
    field: 'OperationDate',
    headerName: '审核时间',
    // width: 200,
    cellClass: 't-c'
  },
  {
    field: 'UpdUserName',
    headerName: '操作人',
    // width: 200,
    cellClass: 't-c'
  },
  {
    field: 'CustomerType',
    headerName: '更改客户类型',
    editable: false,
    // width: 100,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value
      let content = ''
      const item = ClientOptions.find(item => item.key === value)
      if (item) {
        content = item.value || ''
      }
      return content
    },
  },
  {
    field: 'OperationType',
    headerName: '审核结果',
    // width: 200,
    cellClass: 't-c',
    valueFormatter: ({ data: { OperationType } }) => {
      let ret = ''
      switch (OperationType){
        case 1:
          ret = '已审核'
          break;
        case 2:
          ret = '已驳回'
          break;
        default:
            ret = '待审核'
          break;
      }
      return ret;
    }
  },
		{
				field: 'CooperationLevel',
				headerName: '合作等级',
				// width: 200,
				cellClass: 't-c'
		},
  {
    field: 'AduitReason',
    headerName: '申请原因',
    // width: 200,
    cellClass: 't-c'
  },
  {
    field: 'NoAduitReason',
    headerName: '驳回原因',
    // width: 200,
    cellClass: 't-c'
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]
