<template>
  <div class="m-grid p-audit-client">
    <div class="m-grid-search">
      <znl-input
        v-model="searchData.Name"
        border
        class="m-margin--right-10"
        clearable
        placeholder="客户名称"
        width="150px"
      >
      </znl-input>
      <el-button class="m-grid-btn" type="primary" @click="onSearch()"
      >查询
      </el-button
      >
    </div>
    <div class="m-grid-list">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.size"
        :selection="false"
        @onColumnMoved="onColumnMoved"
        :total-count="pagination.total"
        headerHeight="35"
        type="base"
      >
      </grid>
    </div>
      <audit-client-edit-view
      ref="audit"
       @onSearch="onSearch"
    ></audit-client-edit-view>
  </div>
</template>
<script>
import {getAuditClientData, auditClient,NoAduitClientType} from "@/api/client";
import { columns } from "./Modules/AuditClient/config";
import auditClientEditView from "./Modules/AuditClient/auditClientEditView";

import { userColumnMixin } from "@/components/Mixins/UserColumnMixin"
const TableName = "customer-audit-list"

export default {
  name: 'auditClientView',
  mixins: [userColumnMixin],
  components: {
    auditClientEditView,
  },
  data() {
    return {
      itemSource: [],
      columns: columns,
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      searchData: {
        Name: ''
      },
      isEdit: false,
    }
  },
  methods: {
    async onSearch(page = 1) {
      const {pagination, searchData} = this;
      const sendData = {
        Page: page,
        Limit: pagination.size,
      };

      Object.keys(searchData).forEach(key => {
        sendData[key] = searchData[key];
      });

      const data = await getAuditClientData(sendData)

      pagination.page = data.Page;
      pagination.total = data.Total;
      const items = data['Items'];


      if (items.length) {
        items.forEach((item, index) => {
          item.RowIndex = index + 1;
          item.CreatedTime = this.formatDate(item.CreatedTime, 'yyyy-MM-dd hh:mm')
          item.UpdatedTime = this.formatDate(item.UpdatedTime, 'yyyy-MM-dd hh:mm')
        });
      }
      this.itemSource = items;
    },
    async onEditConfig(data) {
      let {isEdit} = this;
    },
    // 初始化表格
    async initColumns() {
      const self = this;
      const OriginColumn = columns.find((item) => item.field === "operation");
      const RateColumn = columns.find((item) => item.field === "EmergencyLevel");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      if (RateColumn) {
        RateColumn.cellRendererParams = {
          // buttons: self.setButtons,
        };
      }
      const newColumns = await this.sort(columns, TableName)
      this.$refs.list.changeColumns(newColumns);
    },

    setButtons(params) {
      let data = params.data;
      console.log(data)

      let buttons = [];
      let self = this;
      if(data.OperationType!=2 && data.OperationType!=1)
      {
        const auditBtn = {
        text: "审核",
        click() {
          self.onAudit(data);
        },
      };
      const rejectedBtn = {
        text: "驳回",
        click() {
          self.onrejected(data);
        },
      };
      buttons.push(auditBtn)
      buttons.push(rejectedBtn)
      return buttons;
      }

    },
    async onColumnMoved(col) {
      if (col.column) {
        const { field, ColumnCode } = col.column.colDef
        const columns = this.$refs.list.$refs.table.columnDefs
        const localColumns = columns.map(a => { return { ...a, ColumnCode: a.field, ColumnName: a.headerName } })
        const newColumns = [...localColumns]
        const index = newColumns.findIndex(a => a.ColumnCode === (field || ColumnCode))
        if (index >= 0) {
          const _col = newColumns[index]
          newColumns.splice(index, 1)
          newColumns.splice(col.toIndex,0, _col)
          await this.onUserColumnMoved(newColumns, TableName)
        }
      }
    },
    async onAudit(data) {
      const params = {
        Id: Number(data.CompanyId),
        CustomerType:data.CustomerType
      };


      this.$confirm("此操作将改变客户类型, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await auditClient(params);
          this.$message({
            type: "success",
            message: "审核成功!",
          });


          await this.onSearch(this.pagination.page);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    // async onrejected(data) {
    //   const params = {
    //     Id: Number(data.CompanyId),
    //     CustomerType: data.CustomerType
    //   };
    //   this.$confirm("此操作将驳回此申请, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(async () => {
    //       await NoAduitClientType(params);
    //       this.$message({
    //         type: "success",
    //         message: "驳回成功!",
    //       });
    //       await this.onSearch();
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消驳回",
    //       });
    //     });
    // },
    async onrejected(data){
        console.log(data)

        this.isEdit=true
        const editItem={
          id:data.CompanyId,
          CompanyName :data.CompanyName,
          CustomerType:data.CustomerType
        };

        this.$refs.audit.onOpen(editItem);
    }
  },
  created() {
    this.onSearch()
  },
  mounted() {
    this.initColumns()
  }
}
</script>
