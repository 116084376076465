import { getUserColumn, saveUserColumn } from "@/api/client"
import {columns} from "@/views/clientManage/Modules/List/config";
const userColumnMixin = {
		// async created() {
		// 		const { TableName } = this
		// 		if (TableName) {
		// 				await this.getUserColumns(TableName);
		// 		}
		// },

		methods: {
			 async	sort(columns, tableName) {
			 		if (!columns || !columns.length)
			 				return columns
						const userColumns = await this.getUserColumns(tableName)
					 if (!userColumns || !userColumns.length)
					 		return columns
					 const newColumns = columns.map(a => {
					 		const uc = userColumns.filter(b => b.ColumnCode === a.field)[0] || { OrderBy: 0 };
					 		return { ...a, ...uc }
					 })
						const ret = newColumns.sort((a, b) => a.OrderBy - b.OrderBy)
					 return ret
				},
				async getUserColumns(TableName) {
						const data = { TableName }
						return await getUserColumn(data)
				},
				async saveUserColumns(data) {
						await saveUserColumn(data)
				},
			 async	onUserColumnMoved(columns, tableName) {
						if (!columns || !columns.length)
								return
						const data = {
								TableName: tableName,
								Columns: columns
						}
						await this.saveUserColumns(data)
				}
		}
}
export { userColumnMixin }
