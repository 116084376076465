<template>
  <div class="c-auditClient-view-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag"
    >
      <section class="c-auditClient-view-dialog-header">
        <div>
           <znl-input
            v-model="editItem.CompanyName"
            border
            class="znl-input"
            placeholder=""
            title="公司名称: "
            :disabled="true"
            title-width="70px"
            width="250px">

          </znl-input></div>
          <div style="padding-top:50px"><znl-input
            v-model="editItem.NoAduitReason"
            border
            class="znl-input"
            placeholder=""
            title="理由: "
            title-width="70px"
            type="textarea"
            width="400px">
          </znl-input></div>
          
        
       
      </section>
      <section class="m-margin--top-10 m-dialog-footer">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import {NoAduitClientType} from "@/api/client";
export default {
  name: "auditClientEditView",
  directives: {
    elDragDialog,
  },
  data() {
    return {
     visible: false,
     isEdit: false,
     title: "驳回",
     editItem:{
       NoAduitReason:"",
       CompanyName:"",
       id:0,
       CustomerType:""
     }
    };
  },
  methods: {
     onOpen(data) {
      console.log(data)
      if (data === null) {
        Object.assign(this.$data.editItem, this.$options.data().editItem);
      } else {
        Object.keys(this.editItem).forEach((key) => {
          this.editItem[key] = data[key];
        });
      }
      this.visible = true;
    },
    async onSave(){
      await NoAduitClientType(this.editItem)
      this.$message.success('驳回成功')
      // this.$emit('onSearch')
      this.visible = false
      this.isEdit=false
    },
    handleDrag() {
      // 拖拽事件
    },
  },
  created() {},
};
</script>
<style lang="scss">

  .c-auditClient-view-dialog
  {
    .el-dialog {
    max-width: 500px !important;
    min-width: 400px !important;
  }
  }

</style>
